import React from 'react'
import { VStack, Text, HStack } from 'native-base';
import { useLocation } from 'react-router-dom';

const Profile = () => {
  const location = useLocation()
  const { profileInfo } = location.state
  if (profileInfo.experiences && profileInfo.experiences.length > 0) {
    return (
      <>
        <HStack display="flex" flexDirection="row" justifyContent="space-between">
          <VStack justifyContent="space-between">
            <Text fontSize="2xl">Full name</Text>
            <Text fontSize="xl">{profileInfo.fullName}</Text>
            <Text fontSize="2xl">Phone number</Text>
            <Text fontSize="xl">{profileInfo.phoneNumber}</Text>
            <Text fontSize="2xl">Radius</Text>
            <Text fontSize="xl">{profileInfo.radius}</Text>
            <Text fontSize="2xl">Zip code</Text>
            <Text fontSize="xl">{profileInfo.zipCode}</Text>
            <Text fontSize="2xl">Classification</Text>
            <Text fontSize="xl">{profileInfo.classifications}</Text>
            <Text fontSize="2xl">Experiences</Text>
          </VStack>
          <VStack>
            {profileInfo.experiences.map(experience => {
              console.log({ experience })
              return (
                <>
                  <Text fontSize="2xl">Company name</Text>
                  <Text fontSize="xl">{experience.companyName}</Text>
                  <Text fontSize="2xl">Title</Text>
                  <Text fontSize="xl">{experience.title}</Text>
                  <Text fontSize="2xl">Start date</Text>
                  <Text fontSize="xl">{experience.startDate}</Text>
                  <Text fontSize="2xl">End date</Text>
                  <Text fontSize="xl">{experience.endDate}</Text>
                  <Text fontSize="2xl">Location</Text>
                  <Text fontSize="xl">{experience.location}</Text>
                  <Text fontSize="2xl">Responsibilities</Text>
                  <Text fontSize="xl">{experience.responsibilities}</Text>
                  {
                    experience.unionName ? (
                      <>
                        <Text fontSize="2xl">Union name</Text>
                        <Text fontSize="xl">{experience.unionName}</Text>
                      </>
                    ) : (
                      <>
                        <Text fontSize="2xl">Self employed</Text>
                        <Text fontSize="xl">{experience.selfEmployed ? 'Yes' : 'No'}</Text>
                      </>
                    )
                    
                  }
                </>
              )
            })}
          </VStack>
        </HStack>
      </>
    );
  } else {
    return (
      <>
        <HStack>
          <VStack>
            <Text fontSize="2xl">Full name</Text>
            <Text fontSize="xl">{profileInfo.fullName}</Text>
            <Text fontSize="2xl">Phone number</Text>
            <Text fontSize="xl">{profileInfo.phoneNumber}</Text>
            <Text fontSize="2xl">Radius</Text>
            <Text fontSize="xl">{profileInfo.radius}</Text>
            <Text fontSize="2xl">Zip code</Text>
            <Text fontSize="xl">{profileInfo.zipCode}</Text>
            <Text fontSize="2xl">Classification</Text>
            <Text fontSize="xl">{profileInfo.classifications}</Text>
          </VStack>
          <VStack>
            <Text fontSize="2xl">Resume</Text>
            <Text fontSize="xl">{profileInfo.resume}</Text>
          </VStack>
        </HStack>
      </>
    );  
  }
};

export default Profile

