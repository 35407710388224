import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthContext } from './auth/AuthContext'

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuthContext()
  console.log({ test: currentUser })
  return currentUser ? children : <Navigate to="/auth" />
}

export default ProtectedRoute