import React, { useState } from "react";

import { Button, FormControl, HStack, Input, Text, VStack, Link, useToast, } from "native-base";
import { Controller, useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useNavigate } from 'react-router-dom'

import { useAuthContext } from "./AuthContext";

function Login() {
  const {
    createAccount,
    setCreateAccount,
    setToConfirm,
    setEmailPassword,
    setCurrentUser,
  } = useAuthContext()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate()
  const toast = useToast()

  const [showPassword, setShowPassword] = useState(false);
  const onLoginSubmit = (data) => handleSubmitLogin(data);

  async function signIn(data) {
    const { email, password } = data
    try {
      const user = await Auth.signIn(
        email,
        password
      );
      setCurrentUser(user)
      navigate('/employee')
    } catch (error) {
      console.log('error in signIn: ', error)
      if (error.code === 'UserNotConfirmedException') {
        setEmailPassword({ email, password })
        setToConfirm(true)
        toast.show({
          description: 'Account already exists. You need to confirm your account.',
          placement: 'bottom',
        })
      } else {
        toast.show({
          description: 'Incorrect email or password, please try again!',
          placement: 'bottom',
        })
      }
    }
  }

  const handleSubmitLogin = async (data) => {
    await signIn(data)
  };

  return (
    <VStack width="40%" space={1}>
      {/* email */}
      <FormControl isRequired isInvalid={"email" in errors}>
        <FormControl.Label>Email</FormControl.Label>
        <Controller
          control={control}
          name="email"
          rules={{ required: "Field is required" }}
          defaultValue=""
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onBlur={onBlur}
              placeholder="Email"
              onChangeText={(val) => onChange(val)}
              value={value}
            />
          )}
        />
        <FormControl.ErrorMessage>
          {errors.email?.message}
        </FormControl.ErrorMessage>
      </FormControl>

      {/* password */}
      <FormControl isRequired isInvalid={"password" in errors}>
        <FormControl.Label>Password</FormControl.Label>
        <Controller
          control={control}
          name="password"
          rules={{
            required: "You must enter a password",
          }}
          defaultValue=""
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              type={showPassword ? "text" : "password"}
              onBlur={onBlur}
              placeholder="Password"
              onChangeText={(val) => onChange(val)}
              value={value}
              InputRightElement={
                <Button
                  size="xs"
                  rounded="none"
                  w="1/6"
                  h="full"
                  onPress={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "Hide" : "Show"}
                </Button>
              }
            />
          )}
        />
        <FormControl.ErrorMessage>
          {errors.password?.message}
        </FormControl.ErrorMessage>
      </FormControl>

      <Button
        onPress={handleSubmit(onLoginSubmit)}
        colorScheme="blue"
        mt={4}
        mb={4}
      >
        Login
      </Button>
      <HStack>
        <Text>Don't have an account?</Text>
        <Link onPress={() => setCreateAccount(!createAccount)}>click here</Link>
      </HStack>
    </VStack>
  );
}

export default Login;
