import React, { useState } from 'react'

import { Text } from 'native-base'

import { useAuthContext } from './AuthContext'
import Login from './Login'
import Signup from './Signup'
import Confirm from './Confirm'

const Auth = () => {
  const { createAccount, toConfirm, resetPassword } = useAuthContext()
  // todo if user exists then navigate to employee
  if (createAccount) {
    return (
      <Signup />
    )
  } else if (resetPassword) {
    return (
      <Text>Password reset not implemented yet</Text>
    )
  } else if (toConfirm) {
    return (
      <Confirm />
    )
  } else {
    return (
      <Login />  
    )
  }
}

export default Auth