import React from "react";
import { Button, FormControl, Input, VStack, Text, HStack, Spacer, useToast } from "native-base";
import { Controller, useForm } from "react-hook-form";
import { API, Auth } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from "./AuthContext";


function Confirm() {
  const navigate = useNavigate()
  const toast = useToast()
  const { emailPassword, setCurrentUser } = useAuthContext()
  const { email, password } = emailPassword
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onConfirmSubmit = (data) => handleSubmitConfirmForm(data);

  const createUserData = async (userData) => {
    try {
      await API.graphql({
        query: mutations.createUser,
        variables: { input: userData },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      navigate('/employee')
    } catch(error) {
      toast.show({
        description: 'An unexpected error occurred, please try again or contact us.',
        placement: 'bottom',
      })
    }
  };

  const signIn = async () => {
    try {
        const user = await Auth.signIn(email, password); 
        setCurrentUser(user)
    } catch (error) {
      toast.show({
        description: 'An unexpected error occurred, please try again or contact us.',
        placement: 'bottom',
      })
    }
  }

  const confirmSignUp = async (data) => {
    try {
      await Auth.confirmSignUp(
        email,
        data.confirm
      );
      await signIn()
      const userData = {
        email,
        userType: 'Employee'
      }
      await createUserData(userData)
    } catch (error) {
      if (error.message.includes('Invalid verification code')){
        toast.show({
          description: 'Incorrect code, please try again or request a new code',
          placement: 'bottom',
        })
      } else {
        toast.show({
          description: 'An unexpected error occurred, please try again or contact us.',
          placement: 'bottom',
        })
      }
    }
  };

  const handleSubmitConfirmForm = async (data) => {
    await confirmSignUp(data);
  };

  const resendConfirmationCode = async () => {
    try {
        await Auth.resendSignUp(email);
        toast.show({
          description: 'Confirmation code has been sent to your email!',
          placement: 'bottom',
        })
    } catch (err) {
        toast.show({
          description: 'Unable to resend verification code, please try again or contact us!',
          placement: 'bottom',
        })
    }
  }


  return (
    <VStack width="40%" space={1}>
      {/* email */}
      <Text fontSize="4xl">Confirm your account</Text>
      <Text fontSize="md">Please enter your confirmation code sent to your email</Text>
      {/* confirm */}
      <FormControl isRequired isInvalid={"confirm" in errors}>
        <FormControl.Label>Confirm</FormControl.Label>
        <Controller
          control={control}
          name="confirm"
          rules={{ required: "Field is required" }}
          defaultValue=""
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onBlur={onBlur}
              placeholder="Confirm"
              onChangeText={(val) => onChange(val)}
              value={value}
            />
          )}
        />
        <FormControl.ErrorMessage>
          {errors.confirm?.message}
        </FormControl.ErrorMessage>
      </FormControl>

      <Button
        onPress={handleSubmit(onConfirmSubmit)}
        colorScheme="blue"
        mt={4}
        mb={4}
      >
        Confirm
      </Button>
      <HStack>
        <Text fontSize="md">Don't have the code?</Text>
        <Spacer />
        <Button onPress={() => resendConfirmationCode()}>Resend</Button>
      </HStack>
    </VStack>
  );
}

export default Confirm;
