/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://t5eyrwq6j5ao5o4yucmij2iqc4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-rjmjsbqtwbeqriaergskni6wmi",
    "aws_cognito_identity_pool_id": "us-west-2:ef4023cf-eb0c-4bee-85a0-b02f57285f0f",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_bH1VSvwTE",
    "aws_user_pools_web_client_id": "13j8tbgmekmao2rtfao04vatt3",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "workforce-storage-64e0c647171845-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
