import React from 'react';
import { Dimensions, StatusBar, Animated, Pressable } from 'react-native';
import { TabView, SceneMap } from 'react-native-tab-view';
import { Box, Center, useColorModeValue } from 'native-base';
import EmployeeForm from './EmployeeForm';
import EmployeeFormWithoutResume from './EmployeeFormWithoutResume';
import EmployeeUnionForm from './EmployeeUnionForm'

const initialLayout = {
  width: Dimensions.get('window').width
};

console.log({ initialLayout })


const renderScene = SceneMap({
  first: EmployeeForm,
  second: EmployeeFormWithoutResume,
  third: EmployeeUnionForm,
});

const Example = () => {
  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([{
    key: 'first',
    title: 'Form with resume'
  }, {
    key: 'second',
    title: 'Form without resume'
  }, {
    key: 'third',
    title: 'Union form'
  }]);

  const renderTabBar = props => {
    const inputRange = props.navigationState.routes.map((x, i) => i);
    return (
      <Box flexDirection="row">
        {props.navigationState.routes.map((route, i) => {
        const opacity = props.position.interpolate({
          inputRange,
          outputRange: inputRange.map(inputIndex => inputIndex === i ? 1 : 0.5)
        });
        const color = index === i ? 'black' : 'gray'
        const borderColor = index === i ? 'cyan.500' : 'cyan.50'
        const backgroundColor = index === i ? 'cyan.50' : 'white'
        return (
          <Box borderBottomWidth="3" borderColor={borderColor} flex={1} alignItems="center" p="3" cursor="pointer" backgroundColor={backgroundColor}>
          <Pressable onPress={() => setIndex(i)}>
            <Animated.Text style={{ color }}>
              {route.title}
            </Animated.Text>
          </Pressable>
        </Box>
        )
      })}
    </Box>
    )
  };

  return <TabView
    navigationState={{ index, routes }}
    renderScene={renderScene}
    renderTabBar={renderTabBar}
    onIndexChange={setIndex}
    initialLayout={initialLayout}
    style={{ marginTop: StatusBar.currentHeight, width: Dimensions.get('window').width }}
  />;
}

export default Example