import React from "react";
import {
  VStack,
  Select,
  Input,
  Button,
  FormControl,
  NativeBaseProvider,
  Text,
  Checkbox,
  TextArea,
  HStack,
  Box,
} from "native-base";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { API, Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import * as mutations from "../../graphql/mutations";

const EmployeeFormWithoutResume = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      experiences: [
        { title: "", startDate: "", endDate: "", responsibilities: "" },
      ],
    },
  });
  const { fields, append, remove } =
    useFieldArray({
      control,
      name: "experiences",
    });

  const navigate = useNavigate()

  const onSubmit = (data) => handleSubmitForm(data);


  const handleSubmitForm = async (data) => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const employeeData = {
      fullName: data.fullName,
      email: currentUser.attributes.email,
      phoneNumber: data.phoneNumber,
      radius: data.radius,
      zipCode: data.zipCode,
      classifications: data.classifications,
      experiences: data.experiences
    }

    try {
      const createdEmployee = await API.graphql({
        query: mutations.createEmployee,
        variables: { input: employeeData },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      // TODO make user call with the id of the created employeeID
      const data = createdEmployee.data.createEmployee;
      const createEmployeeData = {
        classifications: data.classifications,
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        radius: data.radius,
        zipCode: data.zipCode,
        experiences: data.experiences
      };
      navigate("/profile", { state: { profileInfo: employeeData }  });
    } catch (err) {
      console.log({ err })
      throw new Error("error uploading data");
    }
    console.log({ employeeData })
    
  };

  return (
    <VStack width="33%" space={1}>
      <HStack width="100%" space={1}>
        {/* full name */}
        <VStack width="100%" space={1}>
          <FormControl isRequired isInvalid={"fullName" in errors}>
            <FormControl.Label>Full Name</FormControl.Label>
            <Controller
              control={control}
              name="fullName"
              rules={{ required: "Field is required", minLength: 3 }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onBlur={onBlur}
                  placeholder="Full name"
                  onChangeText={(val) => onChange(val)}
                  value={value}
                />
              )}
            />
            <FormControl.ErrorMessage>
              {errors.fullName?.message}
            </FormControl.ErrorMessage>
          </FormControl>

          {/* email */}
          {/* TODO add better validation for the form zod or yup */}
          {/* <FormControl isRequired isInvalid={'email' in errors}>
      <FormControl.Label>Email</FormControl.Label>
      <Controller
        control={control}
        name="email"
        rules={{ required: 'Field is required' }}
        defaultValue=""
        render={({ field: { onChange, onBlur, value }, }) => (
          <Input
            onBlur={onBlur}
            placeholder="Email"
            onChangeText={val => onChange(val)}
            value={value}
          />
        )}
      />
      <FormControl.ErrorMessage>
        {errors.email?.message}
      </FormControl.ErrorMessage>
    </FormControl> */}

          {/* Phone number */}
          <FormControl isRequired isInvalid={"phoneNumber" in errors}>
            <FormControl.Label>Phone number</FormControl.Label>
            <Controller
              control={control}
              name="phoneNumber"
              rules={{ required: "Field is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onBlur={onBlur}
                  placeholder="Phone number"
                  onChangeText={(val) => onChange(val)}
                  value={value}
                />
              )}
            />
            <FormControl.ErrorMessage>
              {errors.phoneNumber?.message}
            </FormControl.ErrorMessage>
          </FormControl>

          {/* Radius */}
          {/* TODO should this be a dropdown of predefined values? */}
          <FormControl isRequired isInvalid={"radius" in errors}>
            <FormControl.Label>Radius</FormControl.Label>
            <Controller
              control={control}
              name="radius"
              rules={{ required: "Field is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onBlur={onBlur}
                  placeholder="Radius"
                  onChangeText={(val) => onChange(val)}
                  value={value}
                />
              )}
            />
            <FormControl.ErrorMessage>
              {errors.radius?.message}
            </FormControl.ErrorMessage>
          </FormControl>

          {/* zip code */}
          <FormControl isRequired isInvalid={"zipCode" in errors}>
            <FormControl.Label>Zip code</FormControl.Label>
            <Controller
              control={control}
              name="zipCode"
              rules={{ required: "Field is required" }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onBlur={onBlur}
                  placeholder="Zip code"
                  onChangeText={(val) => onChange(val)}
                  value={value}
                />
              )}
            />
            <FormControl.ErrorMessage>
              {errors.zipCode?.message}
            </FormControl.ErrorMessage>
          </FormControl>

          {/* classifications */}
          <FormControl isRequired isInvalid={"classifications" in errors}>
            <FormControl.Label>Classification</FormControl.Label>
            <Controller
              control={control}
              name="classifications"
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  placeholder="Choose a classifications"
                  selectedValue={value}
                  onValueChange={(itemValue) => {
                    onChange(itemValue);
                  }}
                >
                  <Select.Item label="Electrician" value="electrician" />
                  <Select.Item label="Plumber" value="plumber" />
                  <Select.Item label="Carpenter" value="carpenter" />
                  <Select.Item label="Demolition" value="demolition" />
                  <Select.Item label="Mechanical" value="mechanical" />
                  <Select.Item label="Steel" value="steel" />
                  <Select.Item
                    label="Glass and Glazing"
                    value="glassAndGlazing"
                  />
                  <Select.Item label="HVAC" value="hvac" />
                  <Select.Item label="Roof" value="roof" />
                  <Select.Item label="Concrete" value="concrete" />
                  <Select.Item label="Asbestos" value="asbestos" />
                  <Select.Item label="Elevator" value="elevator" />
                  <Select.Item
                    label="Paiting and Coating"
                    value="paintingAndCoating"
                  />
                  <Select.Item label="Fencing" value="fencing" />
                  <Select.Item
                    label="Casework and Millwork"
                    value="caseworkAndMillwork"
                  />
                </Select>
              )}
            />
            <FormControl.ErrorMessage>
              {errors.classifications?.message}
            </FormControl.ErrorMessage>
          </FormControl>
        </VStack>
        <VStack width="100%" space={1}>
          {fields.map((item, index) => {
            return (
              <VStack width="100%" space={1} key={item.id}>
                {/* company name */}
                <FormControl
                  isRequired
                  isInvalid={`experiences.${index}.companyName` in errors}
                >
                  <FormControl.Label>Company name</FormControl.Label>
                  <Controller
                    control={control}
                    name={`experiences.${index}.companyName`}
                    rules={{ required: "Field is required", minLength: 3 }}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onBlur={onBlur}
                        placeholder="Company name"
                        onChangeText={(val) => onChange(val)}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
                {/* Title */}
                <FormControl
                  isRequired
                  isInvalid={`experiences.${index}.title` in errors}
                >
                  <FormControl.Label>Title</FormControl.Label>
                  <Controller
                    control={control}
                    name={`experiences.${index}.title`}
                    rules={{ required: "Field is required", minLength: 3 }}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onBlur={onBlur}
                        placeholder="Title"
                        onChangeText={(val) => onChange(val)}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
                {/* Start date */}
                <FormControl
                  isRequired
                  isInvalid={`experiences.${index}.startDate` in errors}
                >
                  <FormControl.Label>Start date</FormControl.Label>
                  <Controller
                    control={control}
                    name={`experiences.${index}.startDate`}
                    rules={{ required: "Field is required", minLength: 3 }}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onBlur={onBlur}
                        placeholder="Start date"
                        onChangeText={(val) => onChange(val)}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
                {/* End date */}
                <FormControl
                  isRequired
                  isInvalid={`experiences.${index}.endDate` in errors}
                >
                  <FormControl.Label>End date</FormControl.Label>
                  <Controller
                    control={control}
                    name={`experiences.${index}.endDate`}
                    rules={{ required: "Field is required", minLength: 3 }}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onBlur={onBlur}
                        placeholder="End date"
                        onChangeText={(val) => onChange(val)}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
                {/* location */}
                <FormControl
                  isRequired
                  isInvalid={`experiences.${index}.location` in errors}
                >
                  <FormControl.Label>Location</FormControl.Label>
                  <Controller
                    control={control}
                    name={`experiences.${index}.location`}
                    rules={{ required: "Field is required", minLength: 3 }}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onBlur={onBlur}
                        placeholder="Location"
                        onChangeText={(val) => onChange(val)}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
                {/* selfEmployed */}
                <FormControl
                  isRequired
                  isInvalid={`experiences.${index}.selfEmployed` in errors}
                >
                  <Controller
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Checkbox
                        onChange={(values) => {
                          onChange(values);
                        }}
                        value={`experiences.${index}.selfEmployed`}
                        colorScheme="blue"
                      >
                        <Text mx={2}>Self employed</Text>
                      </Checkbox>
                    )}
                    name={`experiences.${index}.selfEmployed`}
                    defaultValue=""
                  />
                </FormControl>
                {/* Responsibilities */}
                <FormControl
                  isRequired
                  isInvalid={`experiences.${index}.responsibilities` in errors}
                >
                  <FormControl.Label>Responsibilities</FormControl.Label>
                  <Controller
                    control={control}
                    name={`experiences.${index}.responsibilities`}
                    rules={{ required: "Field is required", minLength: 3 }}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextArea
                        h={20}
                        onBlur={onBlur}
                        placeholder="Responsibilities"
                        onChangeText={(val) => onChange(val)}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
                <Button
                  onPress={() => remove(index)}
                  colorScheme="red"
                  mt={4}
                  mb={4}
                >
                  Remove experience
                </Button>
              </VStack>
            );
          })}
          <Button
            onPress={() =>
              append({ title: "", startDate: "", endDate: "", responsibilities: "" })
            }
            colorScheme="blue"
            mt={4}
            mb={4}
          >
            More experience?
          </Button>
        </VStack>
      </HStack>

      <Button onPress={handleSubmit(onSubmit)} colorScheme="blue" mt={4} mb={4}>
        Submit
      </Button>
    </VStack>
  );
};

export default EmployeeFormWithoutResume;
