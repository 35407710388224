import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { API, Auth, graphqlOperation, Hub } from "aws-amplify";
import {
  FormControl,
  Flex,
  Input,
  VStack,
  Button,
  Checkbox,
  HStack,
  Link,
  useToast,
} from "native-base";
import Disclaimer from "../Disclaimer";
import * as mutations from "../../graphql/mutations";
import { useAuthContext } from "./AuthContext";

/*
 * TODO:
 * - Implement icons
 * - Maybe an error factory
 */

function Signup() {
  const { createAccount, setCreateAccount, toConfirm, setEmailPassword, setToConfirm } = useAuthContext()
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const onFormSubmit = (data) => handleSubmitForm(data);
  const onConfirmSubmit = (data) => handleSubmitConfirmForm(data);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [signUpRes, setSignUpRes] = useState({
    status: false,
    user: {},
    password: "",
  });

  const toast = useToast()

  const navigate = useNavigate();

  const password = useRef({});
  password.current = watch("password", "");
  const disclaimerValue = watch("disclaimer");


  useEffect(() => {
    reset();
  }, [signUpRes]);

  async function signUp(formData) {
    const { email, password } = formData;
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email, // optional
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      });
      setEmailPassword({ email, password })
      setCreateAccount(false)
      setToConfirm(true)
    } catch (error) {
      if (error.message.includes('already exists')) {
        setEmailPassword({email: null, password: null})
        toast.show({
          description: error.message,
          placement: 'bottom',
        })
      } else {
        toast.show({
          description: 'An unexpected error occurred, please try again or contact us!',
          placement: 'bottom',
        })
      }
      console.log(error);
      return error;
    }
  }

  async function signIn() {
    try {
      await Auth.signIn(
        signUpRes.user.username,
        signUpRes.password
      );
    } catch (error) {
      toast.show({
        description: 'An unexpected error occurred, please try again or contact us!',
        placement: 'bottom',
      })
    }
  }

  const createUserData = async (userData) => {
    try {
      await API.graphql({
        query: mutations.createUser,
        variables: { input: userData },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });  
    } catch (error) {
      toast.show({
        description: 'An unexpected error occurred, please try again or contact us!',
        placement: 'bottom',
      })
    }
  };

  const confirmSignUp = async (data) => {
    try {
      await Auth.confirmSignUp(signUpRes.user.username, data.confirm);
      signIn();
      const userData = {
        email: signUpRes.user.username,
        userType: "Employee",
      };
      await createUserData(userData);
    } catch (error) {
      toast.show({
        description: 'An unexpected error occurred, please try again or contact us!',
        placement: 'bottom',
      })
    }
  };

  const handleSubmitForm = async (data) => {
    await signUp(data);
  };

  const handleSubmitConfirmForm = async (data) => {
    await confirmSignUp(data);
  };

  return (
    <>
      <Disclaimer showModal={showModal} setShowModal={setShowModal} />
      <VStack width="40%" space={1}>
        {/* email */}
        <FormControl isRequired isInvalid={"email" in errors}>
          <FormControl.Label>Email</FormControl.Label>
          <Controller
            control={control}
            name="email"
            rules={{ required: "Field is required" }}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // InputLeftElement={<Icon as ={<PersonIcon />} size={5} ml="2" color="muted.400" />}
                onBlur={onBlur}
                placeholder="Email"
                onChangeText={(val) => onChange(val)}
                value={value}
              />
            )}
          />
          <FormControl.ErrorMessage>
            {errors.email?.message}
          </FormControl.ErrorMessage>
        </FormControl>

        {/* password */}
        <FormControl isRequired isInvalid={"password" in errors}>
          <FormControl.Label>Password</FormControl.Label>
          <Controller
            control={control}
            name="password"
            rules={{
              required: "You must specify a password",
              minLength: {
                value: 8,
                message: "Password must have at least 8 characters",
              },
            }}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                type={showPassword ? "text" : "password"}
                onBlur={onBlur}
                placeholder="Password"
                onChangeText={(val) => onChange(val)}
                value={value}
                InputRightElement={
                  <Button
                    size="xs"
                    rounded="none"
                    w="1/6"
                    h="full"
                    onPress={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                }
              />
            )}
          />
          <FormControl.ErrorMessage>
            {errors.password?.message}
          </FormControl.ErrorMessage>
        </FormControl>

        {/* confirm password */}
        <FormControl isRequired isInvalid={"confirmPassword" in errors}>
          <FormControl.Label>Confirm password</FormControl.Label>
          <Controller
            control={control}
            name="confirmPassword"
            rules={{
              required: "Field is required",
              validate: (value) =>
                value === password.current || "The passwords do not match",
            }}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                type={showConfirmedPassword ? "text" : "password"}
                onBlur={onBlur}
                placeholder="Confirm password"
                onChangeText={(val) => onChange(val)}
                value={value}
                InputRightElement={
                  <Button
                    size="xs"
                    rounded="none"
                    w="1/6"
                    h="full"
                    onPress={() =>
                      setShowConfirmedPassword(!showConfirmedPassword)
                    }
                  >
                    {showConfirmedPassword ? "Hide" : "Show"}
                  </Button>
                }
              />
            )}
          />
          <FormControl.ErrorMessage>
            {errors.confirmPassword?.message}
          </FormControl.ErrorMessage>
        </FormControl>

        {/* acknowledgement checkbox */}
        <Controller
          name="disclaimer"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Flex direction="row" alignItems="center">
              <Checkbox value="disclaimer" colorScheme="info" {...field}>
                Terms and conditions
              </Checkbox>
              <Button onPress={() => setShowModal(true)} m={4}>
                view disclaimer
              </Button>
            </Flex>
          )}
        />

        <Button
          onPress={handleSubmit(onFormSubmit)}
          colorScheme="blue"
          mt={4}
          mb={4}
          isDisabled={!disclaimerValue}
        >
          Submit
        </Button>
        <HStack>
          <Link onPress={() => setCreateAccount(!createAccount)}>Login here</Link>
        </HStack>
      </VStack>
    </>
  );
}

export default Signup;
