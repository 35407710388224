import { Flex, Button, Text, useToast, VStack, Link, HStack, Box } from 'native-base'
import { Dimensions } from 'react-native-web'
import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useAuthContext } from './auth/AuthContext'

const Header = () => {
  const windowWidth = Dimensions.get('window').width;
  const { currentUser, setCurrentUser } = useAuthContext()
  console.log({ currentUser })
  const toast = useToast()
  const navigate = useNavigate()

  const navigateHome = () => {
    navigate('/')
  }

  const signOut = async () => {
    try {
      await Auth.signOut()
      setCurrentUser(null)
      navigate('/')
    } catch (err) {
      console.log(err)
      toast.show({
        description: 'Unable to signout, please try again!'
      })
    }
  }

  return (
    <Flex direction="row" alignItems="center" w={windowWidth} backgroundColor='muted.200' justifyContent="space-between">
      <Text>Logo here</Text>
      <Text fontSize="4xl" m={4}>IntelCon Workforce</Text>
      <HStack space={1}>
        <Button m={10} onPress={navigateHome}>Home</Button>
        {currentUser && <Button m={10} onPress={() => signOut()}>Sign out</Button>}
      </HStack>
    </Flex>
  )
}

export default Header