import React from 'react'

import { Modal, ScrollView, Text, Button, } from 'native-base'

const Disclaimer = (props) => {
  const { showModal, setShowModal } = props

  return (
    <>
      <Modal isOpen={showModal} onClose={setShowModal} size="xl">
        <Modal.Content maxH="212">
          <Modal.CloseButton />
          <Modal.Header>Return Policy</Modal.Header>
          <Modal.Body>
            <ScrollView>
              <Text>
              Terms of Service
Overview
IntelCon.us (“IntelCon”) provides online employment marketplace in the construction
industry. The Site connects businesses in the construction sector with relevant job
seekers to make hiring and finding construction related jobs faster and easier (the
“Services”).
By using the Intelcon.us web site (the “Site”), you (the “User”) are agreeing to be
bound by the following terms and conditions (“Terms of Service,” “Agreement”). By
accessing or using the Services, you signify that you have read, understood and agree
to be bound by the terms and conditions of this Agreement, whether or not you are a
registered user, partner, or customer of the Services. If you are accepting on behalf of
your employer or another entity, you represent and warrant that you have full legal
authority to accept these Terms of Service and to grant all permissions and licenses
provided in these Terms on behalf of such employer or other entity and are able to
bind such employer or other entity to the terms and conditions of this Agreement. This
Agreement incorporates the IntelCon’s Privacy Policy and any other Specific Terms
of Service (defined below) as applicable.
IntelCon reserves the right to update and change the Terms of Service from time to
time without notice. Any new features that augment or enhance the Site, including the
release of new tools and resources relating to the Services, shall be subject to the
Terms of Service. Continued use of the Services after any such changes shall
constitute your consent to such changes. If you do not agree to any of these terms of
this Agreement, you are not permitted to use or access (or continue to access), the
Services. IntelCon makes no representation or warranty that any information
provided, regardless of its source (the “Content”), is accurate, complete, reliable,
current, or error-free. You can review the most current version of the Terms of
Service at any time at: http://Intelcon.us/terms.
Certain features of the Services may also have their own specific terms and conditions
that you agree to when you sign up for that particular product, function, or service
(“Specific Terms of Service”).

2

You may not use the Content to determine a person’s eligibility for: (a) credit or
insurance for personal, family, or household purposes; (b) employment; or (c) a
government license or benefit.
Violation of any of the terms below will result in the termination of your Account.
While IntelCon prohibits illegal conduct and Content on the Site, you understand and
agree that IntelCon cannot be responsible for the Content posted on the Site and you
nonetheless may be exposed to such materials. You agree to use the Site and Services
at your own risk.
Account Terms
You must be 18 years or older to use this Site and Services. You may use our Services
only if you can form a binding contract with IntelCon, and only in compliance with
these Terms and all applicable laws.
You must be a human. Accounts registered by “bots” or other automated methods are
not permitted.
You must provide a valid, accurate email address and any other information requested
in order to complete the signup process.
You are responsible for maintaining the security of your account and password.
IntelCon cannot and will not be liable for any loss or damage from your failure to
comply with this security obligation.
You are responsible for all Content posted and activity that occurs under your
account.
One person or legal entity may not maintain more than one account.
You may not use the Service for any illegal or unauthorized purpose. You must not, in
the use of the Service, violate any laws in your jurisdiction (including but not limited
to copyright laws).
If you sign up and/or create an account with IntelCon, you may control your profile
information and how you interact with the Services by changing the settings on your
Settings page. When creating your account or uploading information to the Services
through your account, you represent and warrant that you will provide accurate and
complete information. You are solely responsible for the activity that occurs under

3

your account. You are expected to use &quot;strong&quot; passwords (passwords that use a
combination of upper and lowercase letters, numbers, and symbols) with your
account.
You must exercise caution when accessing your account from a public or shared
computer so that others are not able to view or record your password or other personal
information. You understand and agree that your account is personal to you, and you
agree not to provide any other person with access to the Services using your
username, password, or other security information. You agree to notify us
immediately of any unauthorized access to or use of your username or password or
any other breach of security. You understand that even if you notify us, you will be
totally responsible for all activities that occur under your account. You also agree to
ensure that you logout from your account at the end of each session. You are
responsible for any password misuse or any unauthorized access.
We reserve the right at any time and from time to time, to disable or terminate your
account, any username, password, or other identifier, whether chosen by you or
provided by us, in our sole discretion for any or no reason, including any violation of
any provision of these Terms of Service.
IntelCon may suspend or terminate your account and/or your access to the Services, or
any portion of the Services at any time without notice to you, for any reason,
including if in our sole discretion we determine that you have violated the terms of
this Agreement. Upon any such termination, all the provisions of this Agreement shall
continue to survive except for any provisions that grant you access to any of the
Services. You agree that we will have no liability to you for any termination or
suspension of your access to the Services or your account, or the deletion of
information contained within such account.
API Terms
Customers may access their IntelCon account data via an API (Application Program
Interface). Any use of the API, including use of the API through a third-party product
that accesses IntelCon, is bound by the terms of this agreement plus the following
specific terms:
You expressly understand and agree that IntelCon shall not be liable for any direct,
indirect, incidental, special, consequential or exemplary damages, including but not

4

limited to, damages for loss of profits, goodwill, use, data or other intangible losses
(even if you have been advised of the possibility of such damages), resulting from
your use of the API or third-party products that access data via the API. Further
limitations and clarifications of IntelCon’s liability are found below in the sections
titled “WARRANTY DISCLAIMERS” and “LIMITATION OF LIABILITY.”
Abusive or excessively frequent requests to IntelCon via the API may result in the
temporary or permanent suspension of your account’s access to the API. IntelCon, in
its sole discretion, will determine abuse or excessive usage of the API. IntelCon will
make a reasonable attempt via email to warn the account owner prior to suspension.
IntelCon reserves the right at any time to modify or discontinue, temporarily or
permanently, your access to the API (or any part thereof) with or without notice.
Payment, Refunds, Upgrading and Downgrading Terms
All paid plans must enter a valid credit card. Free accounts are not required to provide
credit card details.
An upgrade from the free plan to any paying plan will immediately bill you.
[After the initial two (2) months of free Services], the Services for paid accounts shall
be billed in advance on a monthly/yearly flat fee basis and are non-refundable. There
will be no refunds or credits for partial months of service, upgrade/downgrade
refunds, or refunds for months unused with an open account. In order to treat
everyone equally, no exceptions will be made.
All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and
you shall be responsible for payment of all such taxes, levies, or duties, excluding
only United States (federal or state) taxes.
For any upgrade or downgrade in plan level, your credit card that you provided will
automatically be charged the new rate on your next billing cycle.
Downgrading your Services may cause the loss of Content, features, or capacity of
your Account. IntelCon does not accept any liability for such loss. For more
information about liability limitations, see our sections below titled “WARRANTY
DISCLAIMER” and “LIMITATION OF LIABILITY”.
Modifications to the Service and Prices

5

IntelCon reserves the right at any time and from time to time to modify or discontinue,
temporarily or permanently, the Services (or any part thereof) with or without notice.
Prices of all Services, including but not limited to monthly subscription plan fees to
the Services, are subject to change upon 30-day’s notice from us. Such notice may be
provided at any time by posting the changes to the IntelCon Site (Intelcon.us) or the
Services itself.
IntelCon shall not be liable to you or to any third party for any modification, price
change, suspension or discontinuance of the Services.
Copyright, Intellectual Property Rights and Content
Ownership
We claim no intellectual property rights over the material you provide to the Services.
Your profile and materials uploaded remain yours.
IntelCon does not pre-screen Content, but IntelCon and its designee have the right
(but not the obligation) in their sole discretion to refuse or remove any Content that is
available via the Services.
You may not duplicate, copy, or reuse any portion of the HTML/CSS, JavaScript, or
visual design elements or concepts without express written permission from IntelCon.
You may not use the IntelCon name without express written permission from
IntelCon.
IntelCon respects the intellectual property of others and asks that Users of our
Services do the same. IntelCon is not responsible for content on other web sites that
you may find or access when using IntelCon Services. Content available on or through
other web sites may be protected by copyright and the intellectual property laws of the
United States and/or other countries. With respect to your use of such content, the
terms of use of those web sites, and not the IntelCon Terms of Service, govern.
Digital Millennium Copyright Act Notice
It is IntelCon’s policy, in appropriate circumstances and at its sole discretion, to
disable and/or terminate the accounts of Users who may infringe the copyrights or
other intellectual property rights of IntelCon and/or others. Pursuant to 17 USC
Section 512 as amended by Title II of the Digital Millennium Copyright Act, IntelCon

6

[has implemented procedures for receiving written notification of claimed
infringements and for processing such claims in accordance with the Act.] If you
believe your copyrights are being infringed, please send a notice to our designated
agent (information below).
The information requested by the Notice of Infringement form substantively complies
with the safe harbor provisions of the Digital Millennium Copyright Act, 17 USC.
512(c)(3)(A), which provides:
To be effective under this subsection, a notification of claimed infringement must be a
written communication provided to the designated agent of a service provider that
includes substantially the following:
1. A physical or electronic signature of a person authorized to act on behalf of the
owner of an exclusive right that is allegedly infringed.
2. Identification of the copyrighted work claimed to have been infringed, or, if
multiple copyrighted works at a single online site are covered by a single
notification, a representative list of such works at that site.
3. Identification of the material that is claimed to be infringing or to be the subject
of infringing activity and that is to be removed or access to which is to be
disabled, and information reasonably sufficient to permit the service provider to
locate the material.
4. Information reasonably sufficient to permit the service provider to contact the
complaining party such as an address, telephone number, and if available, an
electronic mail address at which the complaining party may be contacted.
5. A statement that the complaining party has a good faith belief that use of the
material in the manner complained of is not authorized by the copyright owner,
its agent, or the law.
6. A statement that the information in the notification is accurate, and under
penalty of perjury, that the complaining party is authorized to act on behalf of
the owner of an exclusive right that is allegedly infringed.
Notification from a copyright owner or from a person authorized to act on behalf of
the copyright owner that fails to comply substantially with the provisions above shall
not be considered as providing actual knowledge or an awareness of facts or
circumstances from which infringing activity is apparent.

7

Please provide a Notice of Infringement form each time you wish to report alleged
acts of infringement. Our DMCA Registered Agent is:
Attn: Copyright Agent
IntelCon
______________________
______________________
Email: ______________
General Conditions
Your use of the Service is at your sole risk. The service is provided on an “AS IS” and
“AS AVAILABLE” basis.
Technical support is only provided to paying account holders and is only available via
email.
You understand that IntelCon uses third party vendors and hosting partners to provide
the necessary hardware, software, networking, storage, and related technology
required to run the Services.
You must not modify, adapt, or hack the Services or modify another website so as to
falsely imply that it is associated with the Services, IntelCon, or any other IntelCon
service.
You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the
Services, use of the Services, or access to the Services without the express written
permission by IntelCon.
We may, but have no obligation to, remove Content and Accounts containing Content
that we determine in our sole discretion are unlawful, offensive, threatening, libelous,
defamatory, pornographic, obscene, or otherwise objectionable or violates any party’s
intellectual property or these Terms of Service.
Verbal, physical, written, or other abuse (including threats of abuse or retribution) of
any IntelCon customer, employee, member, or officer will result in immediate account
termination.
You understand that the technical processing and transmission of the Services,
including your Content, may be transferred unencrypted and involve (a) transmissions

8

over various networks; and (b) changes to conform and adapt to technical
requirements of connecting networks or devices.
You must not upload, post, host, or transmit unsolicited email, SMSs, or “spam”
messages.
You must not transmit any worms or viruses or any code of a destructive nature.
If your bandwidth usage exceeds 500 MB/month, or significantly exceeds the average
bandwidth usage (as determined solely by IntelCon) of other IntelCon customers,
IntelCon reserves the right to immediately disable your account or throttle your
hosting until you can reduce your bandwidth consumption.
The failure of IntelCon to exercise or enforce any right or provision of the Terms of
Service shall not constitute a waiver of such right or provision. The Terms of Service
constitutes the entire agreement between you and IntelCon and govern your use of the
Service, superseding any prior agreements between you and IntelCon (including, but
not limited to, any prior versions of the Terms of Service).
WARRANTY DISCLAIMER
YOU AGREE THAT YOUR USE OF THE SITE SHALL BE AT YOUR SOLE
RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, INTELCON, ITS
OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL
WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE
SERVICE AND YOUR USE THEREOF. INTELCON MAKES NO WARRANTIES
OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF
THIS SITE&#39;S CONTENT OR THE CONTENT OF ANY SITES LINKED TO THIS
SITE AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I)
ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL
INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
RESULTING FROM YOUR ACCESS TO AND USE OF OUR SERVICE, (III)
ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR
CESSATION OF TRANSMISSION TO OR FROM OUR SERVICE, (IV) ANY
BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY,

9

AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY
LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE
MADE AVAILABLE VIA THE SERVICE. INTELCON DOES NOT WARRANT,
ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY
THROUGH THE SERVICE OR ANY HYPERLINKED SERVICE OR FEATURED
IN ANY BANNER OR OTHER ADVERTISING, AND INTELCON WILL NOT BE
A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF
PRODUCTS OR SERVICE. AS WITH THE PURCHASE OF A PRODUCT OR
SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU
SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
APPROPRIATE.
LIMITATION OF LIABILITY
YOU AGREE THAT UNDER NO CIRCUMSTANCES INTELCON SHALL WE
BE LIABLE FOR DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
SPECIAL, PUNITIVE, EXEMPLARY, OR ANY OTHER DAMAGES ARISING
OUT OF YOUR USE OF THE SITE OR SERVICES. ADDITIONALLY,
INTELCON IS NOT LIABLE FOR DAMAGES IN CONNECTION WITH (I) ANY
FAILURE OF PERFORMANCE, ERROR, OMISSION, DENIAL OF SERVICE,
ATTACK, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR
TRANSMISSION, COMPUTER VIRUS, OR LINE OR SYSTEM FAILURE; (II)
LOSS OF REVENUE, ANTICIPATED PROFITS, BUSINESS, SAVINGS,
GOODWILL OR DATA; AND (III) THIRD PARTY THEFT OF, DESTRUCTION
OF, UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF YOUR
INFORMATION OR PROPERTY, REGARDLESS OF OUR NEGLIGENCE,
GROSS NEGLIGENCE, FAILURE OF AN ESSENTIAL PURPOSE AND
WHETHER SUCH LIABILITY ARISES IN NEGLIGENCE, CONTRACT, TORT,
OR ANY OTHER THEORY OF LEGAL LIABILITY. THE FOREGOING APPLIES
EVEN IF INTELCON HAS BEEN ADVISED OF THE POSSIBILITY OF OR
COULD HAVE FORESEEN THE DAMAGES. IN THOSE STATES THAT DO
NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR THE
DAMAGES, OUR LIABILITY IS LIMITED TO THE FULLEST POSSIBLE

10

EXTENT PERMITTED BY LAW. IN NO EVENT SHALL INTELCON’S
CUMULATIVE LIABILITY TO YOU EXCEED THE TOTAL PURCHASE PRICE
OF THE SERVICES YOU HAVE PURCHASED FROM INTELCON, AND IF NO
PURCHASE HAS BEEN MADE BY YOU, INTELCON’S CUMULATIVE
LIABILITY TO YOU SHALL NOT EXCEED $100.
Questions about the Terms of Service should be sent to _________________.
Additional Information for Job Seekers:
Resume Security
To protect your personal information never provide social security, credit card or bank
account numbers to prospective employers. Due to fraud being prevalent online, you
need to be mindful about the type of information you include on your resume. For
example, the following personal information should never be included:
National Identification Number
Driver&#39;s license number
Bank account information
Credit card information
Passwords
Date of birth
NOTE: You should also never share the personal information listed above with a
prospective employer until you are confident that the employer and employment
opportunity is legitimate.
Job Applications; Resume Database
As a Job Seeker, you can (i) use the Services to search for job postings (“Job
Postings”), (ii) apply for jobs (“Job Applications”), and (iii) include your resume and
other applicable information in a resume database (the, “Resume Database”). For
purposes of these Terms of Service, references to “resume” or “CV” shall also mean a
curriculum vitae, profile, references, recommendations, and other similar written
overviews or assessments of a person&#39;s experience and qualifications.

11

If you have an IntelCon account, provide your resume/CV to IntelCon, you agree that
we may (a) use the information you provide to send you information about Job
Postings that may be of interest to you; and (b) display or provide your resume/CV
and profile information to an employer or recruiter who may be interested in
candidates like you.
To the extent that you wish us to share your information and resume/CV with
potential employers, recruiters, or staffing agencies (including, where you have
submitted your resume/CV to the Resume Database or because you have applied to a
Job Posting on an IntelCon website), you consent to our sharing your resume/CV,
together with any additional information you provide to us during the application
process. This means that you give us a perpetual, irrevocable, non-exclusive, royalty-
free, worldwide license (and right to sub-license), of any rights, including intellectual
property rights, in your resume/CV and any additional information you provide to us,
to share or otherwise use such resume/CV and additional information as we
reasonably require to fulfil the Services, we provide to you.
Job Postings
By applying to a Job Posting on Intelcon.us website, you give us permission to store
your information on the website and to share your information (including your
resume/CV), with the entity that created the Job Posting.
You can choose how to submit your Job Application using the options offered through
the Services. Once you submit a Job Application, your resume and other applicable
information will be shared with the entity that created the Job Posting as long as any
parameters selected by that organization are met, where applicable. You should
therefore ensure that the information you provide is complete and accurate, and only
contains information that you are comfortable sharing.
If you ask IntelCon to submit a message to any employer or third party or if you apply
for a job using the Site, you accept that IntelCon does not guarantee that the recipient
entity will receive, access, view, or respond to your requests or Job Applications, or
that the transmission of data will be error-free. Any screener questions provided by
the organization creating a Job Posting are entirely in the control of that organization
and if you require alternate screener questions or application process, you must
contact the applicable organization directly.
Applying to Job Postings on the Site

12

When you apply to a Job Posting on the Site or through the app, if all parameters
selected by the organization that created the Job Posting are met, IntelCon will send
the resume associated with your account.
It is your responsibility to ensure that the resume associated with your account is
always updated, current and accurate before you apply to a Job Posting or opt-in to the
Resume Database, so an employer receives the most up-to-date information about
you. In order to delete old resumes, edit your resume, or upload a new resume, please
go to the Profile settings in your User account.
Additionally, although IntelCon requests that companies maintain the confidentiality
of the Job Applications and resumes/CVs they receive through the Services, IntelCon
cannot and does not guarantee that the information you provide will be held in
confidence or properly secured by the applicable recipient organization or person.
Resume Database
IntelCon has a feature which permits job seekers to include their resume/CV and other
profile information in the Resume Database to be stored on the Site.
If you add your resume/CV to the Resume Database, then you are:
Consenting to IntelCon matching words, skills, or certifications that are present or
implied in your resume or other application materials to words, skills, or certifications
present or implied in a Job Posting or an employer’s or recruiter’s search of terms, and
if there are any matches, to present matching resumes/CVs to the applicable employer
or recruiter and to send information to you about Job Postings that may be of interest
to you.
Acknowledging that we may change the resume and other applicable information into
another format to create a profile view and/or enable searchable text for an employer;
the employer will also be able to see your resume in the form in which you originally
submitted it to IntelCon.
Interactions with Other Users; Scams; Confidentiality
You are solely responsible for your interactions with Users whom you contact or who
contact you. Note: there are risks, including, but not limited to, the risk of physical
harm, that may arise when dealing with strangers, including persons who may be
acting under false pretenses. You should therefore use caution with regard to the
information you elect to share as part of your User profile or Job Application or
resume/CV that. Only list the contact information that you are comfortable disclosing
to others.

13

In addition to carefully reviewing this Agreement, you should conduct your own due
diligence on potential employers and Job Postings that may be of interest to you. As
previously mentioned, IntelCon does not authenticate Users or guarantee that a Job
Posting is suitable, legitimate or real.
Additional Information for Organizations:
No Warranties or Obligations
You agree that IntelCon makes no representations, warranties, promises or guarantees
regarding any content provided or generated by third parties, including, without
limitation, the content of messages, Job Postings and resumés. You further agree that
IntelCon acts as a passive conduit for the distribution, provision, and publication of
the Content, and has no obligation to screen or verify the accuracy, legality,
legitimacy, truthfulness, or completeness of Content, and accordingly, you accept that
IntelCon is not responsible and has no liability for the Content. You understand that
you may be exposed to the Content that is inaccurate, incomplete, illegal, misleading,
false, offensive, constitutes spam, or is otherwise unsuited to your purpose, and you
accept that it is your responsibility to verify the quality, accuracy, truthfulness,
legality or reliability of the Content, including, without limitation, resumes/CVs and
Job Postings and content of messages. Your reliance on any the Content is at your
own risk.
Notwithstanding the foregoing, although IntelCon has no obligation to screen the
Content, to the extent that IntelCon becomes or is made aware of the Content that may
or does (i) violate the terms of these Terms of Service or any other agreement you
might have with IntelCon, or (ii) violate any law or regulation, or (iii) violate the
rights of third parties, or (iv) create liability for IntelCon or otherwise negatively
impact IntelCon, IntelCon reserves the right to reject and/or remove such Content, and
suspend and/or terminate any user account associated with such Content.
User Content and the U.S. Communications Decency Act
Please note Section 230 of the U.S. Communications Decency Act (“CDA”) (and the
equivalent or similar laws in your jurisdiction) is intended to exclude or limit the
liability of online service providers such as IntelCon, when such online service
providers provide or make available access to third-party user generated content (see §
230 (c)(1) which states: No provider or user of an interactive computer service shall

14

be treated as the publisher or speaker of any information provided by another
information content provider).
The decision by IntelCon to remove or not post or distribute any Content, does not by
itself amount to participation in the creation of such Content and, accordingly, does
not constitute a waiver of the immunity afforded by the CDA. Additionally, § 230
(c)(2) of the CDA specifically provides: “No provider or user of an interactive
computer service shall be held liable on account of (A) any action voluntarily taken in
good faith to restrict access to or availability of material that the provider or user
considers to be obscene, lewd, lascivious, filthy, excessively violent, harassing, or
otherwise objectionable whether or not such material is constitutionally protected…”
User Interactions and Assumption of Risk
You understand and agree that IntelCon does not, and cannot, confirm that each User
is who he/she/they claim to be and their eligibility for employment. We are not
responsible for authenticating Users and therefore it is your responsibility to conduct
the appropriate due diligence before communicating or interacting with Users,
including, without limitation, job seekers, employers, staffing agencies, and recruiters.
You assume all risks associated with Users with whom you come into contact. If you
have any disputes or issues with any User you agree to pursue any remedies directly
with the applicable User and you release IntelCon, its subsidiaries, and their
respective employees, directors and agents from all claims, demands and damages of
every kind (actual and consequential and direct and indirect) known and unknown,
suspected and unsuspected, disclosed and undisclosed, to the fullest extent permitted
by law.
Content Disclosure
IntelCon reserves the right to disclose Content and any information related to the
provider of such Content, to third parties in connection with the operation and
provision of the Services, to enforce the terms of any agreement that we have with
you, to comply with legal obligations and requests from governmental authorities, law
enforcement agencies, court orders, subpoenas, etc., and to protect the interests of
IntelCon where necessary. For the avoidance of doubt, you agree that IntelCon has the
right to disclose your identity to a third party who is claiming that any Content you
posted, provided or uploaded is fraudulent, false, or misleading or constitutes a

15

violation of the law, or a violation of their intellectual property or ownership rights, or
of their right to privacy.
              </Text>
            </ScrollView>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  );
}

export default Disclaimer
