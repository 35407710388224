import React, { useState, useEffect } from "react";

import {
  useForm,
  Controller,
  useController,
  useFieldArray,
} from "react-hook-form";
import { Auth, Amplify, API, graphqlOperation, Storage } from "aws-amplify";
import awsconfig from "../../aws-exports";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";

import {
  VStack,
  Select,
  Input,
  Button,
  FormControl,
  NativeBaseProvider,
  Text,
  Checkbox,
  TextArea,
  HStack,
  Switch,
} from "native-base";

import EmployeeFormWithoutResume from './EmployeeFormWithoutResume'
import { useNavigate } from "react-router-dom";

Amplify.configure(awsconfig);
Storage.configure({ level: "protected" });

const FileInput = ({ control, name }) => {
  const { field } = useController({ control, name });
  const [value, setValue] = React.useState("");
  return (
    <input
      type="file"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        field.onChange(e.target.files);
      }}
    />
  );
};

function EmployeeForm() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => handleSubmitForm(data);
  const [hasCompletedForm, setHasCompletedForm] = useState(false);
  const [profileInfo, setProfileInfo] = useState({});
  const [showFormWithoutResume, setShowFormWithoutResume] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      const currentUser = await Auth.currentAuthenticatedUser();
      try {
        const employeeQueryRes = await API.graphql({
          query: queries.employeeByEmail,
          variables: { email: currentUser.attributes.email },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        if (employeeQueryRes.data.employeeByEmail.items.length > 0) {
          // TODO need a better way to get this, incase there are multiple version of the profile
          const profileRes = employeeQueryRes.data.employeeByEmail.items[0];
          console.log({ profileRes })
          navigate("/profile", { state: { profileInfo: profileRes }  });
        }

        return employeeQueryRes;
      } catch (err) {
        console.log({ err });
        throw new Error("error getting form data: ", err);
      }
    };
    fetchData();
  }, []);


  const handleSubmitForm = async (data) => {
    const { resume } = data;
    const currentUser = await Auth.currentAuthenticatedUser();
    const currentCredentials = await Auth.currentUserCredentials();
    const s3fileName = `name:${resume[0].name}-email:${currentUser.attributes.email}`;

    const employeeData = {
      fullName: data.fullName,
      email: currentUser.attributes.email,
      phoneNumber: data.phoneNumber,
      radius: data.radius,
      zipCode: data.zipCode,
      classifications: data.classifications,
      resume: resume[0].name,
    };

    // https://docs.amplify.aws/lib/storage/list/q/platform/js/#protected-level-list
    await uploadResume(resume[0], currentUser.attributes.email);
    await createEmployee(employeeData);
  };

  const createEmployee = async (employeeData) => {
    try {
      const createdEmployee = await API.graphql({
        query: mutations.createEmployee,
        variables: { input: employeeData },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      // TODO make user call with the id of the created employeeID
      const data = createdEmployee.data.createEmployee;
      const createEmployeeData = {
        classifications: data.classifications,
        resume: data.resume,
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        radius: data.radius,
        zipCode: data.zipCode,
      };
      navigate("/profile", { state: { profileInfo: createEmployeeData }  });
    } catch (err) {
      throw new Error("error uploading data");
    }
  };

  const uploadResume = async (resume, email) => {
    try {
      await Storage.put(`name:${resume.name}-email:${email}`, resume, {
        contentType: resume.type,
      });
    } catch (err) {
      throw new Error("error uploading resume: ", err);
    }
  };

  return (
    <>
      <VStack width="33%" space={1}>
        {/* full name */}
        <FormControl isRequired isInvalid={"fullName" in errors}>
          <FormControl.Label>Full Name</FormControl.Label>
          <Controller
            control={control}
            name="fullName"
            rules={{ required: "Field is required", minLength: 3 }}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onBlur={onBlur}
                placeholder="Full name"
                onChangeText={(val) => onChange(val)}
                value={value}
              />
            )}
          />
          <FormControl.ErrorMessage>
            {errors.fullName?.message}
          </FormControl.ErrorMessage>
        </FormControl>

        {/* email */}
        {/* TODO add better validation for the form zod or yup */}
        {/* <FormControl isRequired isInvalid={'email' in errors}>
      <FormControl.Label>Email</FormControl.Label>
      <Controller
        control={control}
        name="email"
        rules={{ required: 'Field is required' }}
        defaultValue=""
        render={({ field: { onChange, onBlur, value }, }) => (
          <Input
            onBlur={onBlur}
            placeholder="Email"
            onChangeText={val => onChange(val)}
            value={value}
          />
        )}
      />
      <FormControl.ErrorMessage>
        {errors.email?.message}
      </FormControl.ErrorMessage>
    </FormControl> */}

        {/* Phone number */}
        <FormControl isRequired isInvalid={"phoneNumber" in errors}>
          <FormControl.Label>Phone number</FormControl.Label>
          <Controller
            control={control}
            name="phoneNumber"
            rules={{ required: "Field is required" }}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onBlur={onBlur}
                placeholder="Phone number"
                onChangeText={(val) => onChange(val)}
                value={value}
              />
            )}
          />
          <FormControl.ErrorMessage>
            {errors.phoneNumber?.message}
          </FormControl.ErrorMessage>
        </FormControl>

        {/* Radius */}
        {/* TODO should this be a dropdown of predefined values? */}
        <FormControl isRequired isInvalid={"radius" in errors}>
          <FormControl.Label>Radius</FormControl.Label>
          <Controller
            control={control}
            name="radius"
            rules={{ required: "Field is required" }}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onBlur={onBlur}
                placeholder="Radius"
                onChangeText={(val) => onChange(val)}
                value={value}
              />
            )}
          />
          <FormControl.ErrorMessage>
            {errors.radius?.message}
          </FormControl.ErrorMessage>
        </FormControl>

        {/* zip code */}
        <FormControl isRequired isInvalid={"zipCode" in errors}>
          <FormControl.Label>Zip code</FormControl.Label>
          <Controller
            control={control}
            name="zipCode"
            rules={{ required: "Field is required" }}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onBlur={onBlur}
                placeholder="Zip code"
                onChangeText={(val) => onChange(val)}
                value={value}
              />
            )}
          />
          <FormControl.ErrorMessage>
            {errors.zipCode?.message}
          </FormControl.ErrorMessage>
        </FormControl>

        {/* classifications */}
        <FormControl isRequired isInvalid={"classifications" in errors}>
          <FormControl.Label>Classification</FormControl.Label>
          <Controller
            control={control}
            name="classifications"
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                placeholder="Choose a classifications"
                selectedValue={value}
                onValueChange={(itemValue) => {
                  onChange(itemValue);
                }}
              >
                <Select.Item label="Electrician" value="electrician" />
                <Select.Item label="Plumber" value="plumber" />
                <Select.Item label="Carpenter" value="carpenter" />
                <Select.Item label="Demolition" value="demolition" />
                <Select.Item label="Mechanical" value="mechanical" />
                <Select.Item label="Steel" value="steel" />
                <Select.Item
                  label="Glass and Glazing"
                  value="glassAndGlazing"
                />
                <Select.Item label="HVAC" value="hvac" />
                <Select.Item label="Roof" value="roof" />
                <Select.Item label="Concrete" value="concrete" />
                <Select.Item label="Asbestos" value="asbestos" />
                <Select.Item label="Elevator" value="elevator" />
                <Select.Item
                  label="Paiting and Coating"
                  value="paintingAndCoating"
                />
                <Select.Item label="Fencing" value="fencing" />
                <Select.Item
                  label="Casework and Millwork"
                  value="caseworkAndMillwork"
                />
              </Select>
            )}
          />
          <FormControl.ErrorMessage>
            {errors.classifications?.message}
          </FormControl.ErrorMessage>
        </FormControl>

        {/* resume upload */}
        <FormControl isRequired isInvalid={"resume" in errors}>
          <FormControl.Label>Resume</FormControl.Label>
          <FileInput name="resume" control={control} />
          <FormControl.ErrorMessage>
            {errors.resume?.message}
          </FormControl.ErrorMessage>
        </FormControl>

        <Button
          onPress={handleSubmit(onSubmit)}
          colorScheme="blue"
          mt={4}
          mb={4}
        >
          Submit
        </Button>
      </VStack>
    </>
  )
}

export default function () {
  return (
    <NativeBaseProvider>
      <EmployeeForm />
    </NativeBaseProvider>
  );
}
