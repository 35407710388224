import { Auth } from 'aws-amplify';
import React, { createContext, useEffect, useState } from 'react'

const AuthContext = createContext({ currentUser: null });

export const AuthContextProvider = ({ children }) => {
  const [createAccount, setCreateAccount] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)
  const [toConfirm, setToConfirm] = useState(false)
  const [emailPassword, setEmailPassword] = useState({ email: null, password: null })
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      setCurrentUser(user)
    })
  }, [])


  const context = {
    createAccount,
    setCreateAccount,
    resetPassword,
    setResetPassword,
    toConfirm,
    setToConfirm,
    setEmailPassword,
    emailPassword,
    currentUser,
    setCurrentUser,
  }

  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => React.useContext(AuthContext)