import React from 'react'
import { Box, HStack, Link } from 'native-base'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from './auth/AuthContext'
 

function Home() {
  const navigate = useNavigate()
  const { setCreateAccount, currentUser } = useAuthContext()
  const navigateTo = (route) => {
    setCreateAccount(route === '/auth')
    navigate(route)
  }

  return (
    <HStack>
      <Link onPress={() => navigateTo('/employee')} m={8} mt="8">
        <Box px="3" py="2" bg="primary.600" rounded="sm" _text={{
          color: "white",
          fontWeight: "medium"
        }}>
          Employee
        </Box>
      </Link>
      { !currentUser && (
        <Link onPress={() => navigateTo('/auth')} m={8} mt="8">
        <Box px="3" py="2" bg="primary.600" rounded="sm" _text={{
          color: "white",
          fontWeight: "medium"
        }}>
          Create account
        </Box>
      </Link>
      )
      }
    </HStack>
  )
}

export default Home