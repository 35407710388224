import React from "react";
import { Routes, Route } from 'react-router-dom'
import { Amplify } from "aws-amplify";
import './App.css';

import '@aws-amplify/ui-react/styles.css'
import awsExports from '../aws-exports'

import EmployeeForm from './employee/EmployeeForm';
import EmployeeContainer from './employee/EmployeeFormContainer'
import Playground from './Playground'
import Home from './Home'
import Header from './Header'

import { NativeBaseProvider, Box } from 'native-base'
import Auth from './auth/Auth'
import Profile from "./profile/Profile";
import ProtectedRoute from "./ProtectedRoute";

Amplify.configure(awsExports)

function App() {
  return (
    <NativeBaseProvider>
        <Box flex={1} bg="#fff" alignItems="center" justifyContent="center" pl="10">
          <Header />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/auth" exact element={<Auth />} />
            <Route path="/employee" exact element={
              <ProtectedRoute>
                <EmployeeContainer />
              </ProtectedRoute>
            }/>
            <Route path="/profile" exact element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }/>
            <Route path="/employer" exact element={null}/>
            <Route path="playground" exact element={<Playground />} />
          </Routes>
        </Box>
    </NativeBaseProvider>
  );
}

export default App;
